import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						隔測式温度計（在庫規格品）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/lb-100s.pdf" target={"_blank"} rel="noreferrer">
									LB-100Sシリーズ
								</a>
							</li>

							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/lb-150s.pdf" target={"_blank"} rel="noreferrer">
									LB-150Sシリーズ
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/rs-well.pdf" target={"_blank"} rel="noreferrer">
									隔測式温度計用保護管
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
